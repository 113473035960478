import { useTranslation } from 'react-i18next';
import { 
  OrderedListOutlined, 
  EnvironmentOutlined,
  ShoppingCartOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import IMenuItem from '@/interfaces/IMenuItem';
import usePermissions from '@/hooks/usePermissions';
import EBasicActions from '@/enums/EBasicActions';
import EPage from '@/enums/EPages';
import useAuth from '@/hooks/useAuth';

const MenuItems = () => {
  const { t } = useTranslation('common');
  const { isValidAction } = usePermissions();
  const { authenticated } = useAuth();

  const items: IMenuItem[] = [
    // {
    //   key: '/orders',
    //   label: t('g.orders'),
    //   icon: <OrderedListOutlined />,
    //   isHidden: () => !authenticated,
    // },
    // {
    //   key: '/addresses',
    //   label: t('g.addresses'),
    //   icon: <EnvironmentOutlined />,
    //   isHidden: () => !authenticated,
    // },
    // {
    //   key: '/payments',
    //   label: t('g.payment_methods'),
    //   icon: <CreditCardOutlined />,
    //   isHidden: () => !authenticated,
    // },
    // {
    //   key: '/shopping_cart',
    //   label: t('g.shopping_cart'),
    //   icon: <ShoppingCartOutlined />,
    //   isHidden: () => false,
    // },
    // {
    //   key: '/shopping_carts',
    //   label: t('g.shopping_carts'),
    //   icon: <ShoppingCartOutlined />,
    //   isHidden: () => !isValidAction(EBasicActions.Create, EPage.ExtendedCheckout)
    // },
    // {
    //   key: '/payment_sources',
    //   label: t('g.payment_sources'),
    //   icon: <CreditCardOutlined />,
    //   isHidden: () => !authenticated
    // },
  ];

  return items;
};

export default MenuItems;
