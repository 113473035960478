import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Divider, Card, Modal, Typography } from 'antd';
import PriceDetails from '@/containers/views/ItemDetails/PriceDetails';
import IProduct from '@/interfaces/IProduct';
import useLocation from '@/hooks/useLocation';
import getTranlation from '@/helpers/getTranslation';
import useBreakpoints from '@/hooks/useBreakpoint';
import useProductConfiguration from '@/containers/views/ItemDetails/useProductConfiguration';
import BadgesList from '@/components/General/BadgesList';
import ItemAttachmentsSection from '@/components/Item/ItemAttachmentsSection';
import EAttachmentTypes from '@/enums/EAttachmentTypes';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import { isProductHaveShippingTypeInWarehouse } from '@/services/ShippingTypeInWarehouse';
import Description from '@/components/General/Description';
import RelatedProducts from '@/components/RelatedProducts';
import Title from '@/components/General/Title/Title';
import ImagesPreview from '@/components/ImagesPreview';
import SelectionMix from '@/interfaces/ISelectionMix';
import ProductAttributeOptions from '@/components/General/ProductAttributeOptions';
import { useTranslation } from 'react-i18next';
import EVisibility from '@/enums/EVisibility';
import EInventoryStatus from '@/enums/EInventoryStatus';
import { useNavigate } from 'react-router-dom';
import { isProductHaveDeliveryTypeInWarehouse } from '@/services/DeliveryPointService';
import precision from '@/helpers/precision';

const { Paragraph } = Typography;

interface IProductDetailsView {
  product: IProduct;
  qty: number;
  hasMoreOptions?: boolean;
  hasDisclaimer?: boolean;
  onIncreaseItemToAdd: () => void; 
  onDecreaseItemToAdd: () => void; 
  onClickMoreOptions?: () => void;
  onAddToCart?: (qtyToAdd?: number) => void;
  onBuyNow?: (qtyToAdd?: number) => void;
  onChangeSelection: React.Dispatch<React.SetStateAction<string | SelectionMix | undefined>>
}
 
const ProductDetailsView = ({
  product,
  qty,
  hasMoreOptions,
  onClickMoreOptions,
  onAddToCart = () => {},
  onBuyNow = () => {},
  onIncreaseItemToAdd,
  onDecreaseItemToAdd,
  onChangeSelection
}: IProductDetailsView) => {
  const {
    loading,
    relatedItems,
    configuration,
    getProductConfig,
    getRelatedProducts,
    getWarehouseProductConfig,
  } = useProductConfiguration();
  
  const { selectedWarehouse } = useLocation();
  const { t } = useTranslation('common');
  const { 
    isBreakpointEqualsAndBelow,
    isBreakpointEqualsAndAbove,
  } = useBreakpoints();

  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal();

  const [hasShippingType, setHasShippingType] = useState(false);
  const [hasDeliveryPoint, setHasDeliveryPoint] = useState(false);

  const visibilityModal = useCallback(() => {
    modal.warning({
      title: 'Ups!',
      content: (
        <>
          <Paragraph>{t('g.item_not_available')}</Paragraph>
        </>
      ),
      okText: t('g.back'),
      onOk: () => {
        navigate('/');
      },
      maskStyle: {
        backdropFilter: 'blur(4px)',
      },
      maskClosable: false,
      keyboard: false,
    });
  }, []);

  const isScreenEqualsAndBelowMd = isBreakpointEqualsAndBelow('md');
  const isScreenEqualsAndBelowSm = isBreakpointEqualsAndBelow('sm');
  const isScreenEqualsAndAboveLg = isBreakpointEqualsAndAbove('lg');
  const productName = getTranlation(product.name);
  const productDesc = getTranlation(product.desc);
  const productSku = `SKU: ${product.sku}`;

  const attachments = product.productAttachments;
  const datasheets = attachments.filter(
    attac => attac.type === EAttachmentTypes.Datasheet
  );

  const guarantees = attachments.filter(
    attac => attac.type === EAttachmentTypes.Guarantee
  );

  const disclaimers = attachments.filter(
    attac => attac.type === EAttachmentTypes.Disclaimer
  );

  const deliveryMethodsAllowed: EDeliveryMethod[] = [];
  
  if (selectedWarehouse && hasDeliveryPoint) {
    deliveryMethodsAllowed.push(EDeliveryMethod.Pickup);
  }
  
  if (hasShippingType) {
    deliveryMethodsAllowed.push(EDeliveryMethod.Shipping);
  }

  const getMaxPurchaseSelectionQty = () => {
    const allowsSale = configuration.itemAvailability?.allowSaleWithoutInventory;
    if (allowsSale) return undefined;

    const currentInventoryQty = configuration.itemAvailability?.currentQty || 0;
    const productStepper = product.stepper;

    const residue = currentInventoryQty % productStepper;

    let maxPurchaseSelectionQty = currentInventoryQty

    if(residue !== currentInventoryQty && residue > 0){
      maxPurchaseSelectionQty = currentInventoryQty - residue;
    }

    return maxPurchaseSelectionQty;
  };

  function onIncreaseItemToAddHandler(){
    // getProductConfiguration();
    onIncreaseItemToAdd();
  }
  
  function onDecreaseItemToAddHandler(){
    // getProductConfiguration();
    onDecreaseItemToAdd();
  }

  function getProductConfiguration(){
    if (selectedWarehouse) {
      getWarehouseProductConfig(product, selectedWarehouse);

      isProductHaveShippingTypeInWarehouse({
        productIds: [product.id],
        warehouseId: selectedWarehouse,
      }).then(res => setHasShippingType(res));

      isProductHaveDeliveryTypeInWarehouse({
        productId: product.id,
        warehouseId: selectedWarehouse,
      }).then(res => setHasDeliveryPoint(res));

      return;
    }
    getProductConfig(product);
  }

  const onContactAssociate = () => navigate(`/contact_associate?id=${product.id}`);

  const ProductPriceDetails = ({ showTitle = true }: { showTitle?: boolean }) => {
    const { stepper } = product;
    const PRECISION = precision(stepper);
    const qtyValue = +(qty * product.stepper).toFixed(PRECISION);
    return (
      <PriceDetails
        title={productName}
        subtitle={productSku}
        showTitle={showTitle}
        qtyInputConfig={{
          min: product.stepper,
          max: getMaxPurchaseSelectionQty(),
          value: qtyValue,
          label: getTranlation(product.unit.label),
          disableIncrease: loading,
          disableDecrease: loading,
        }}
        priceConfig={configuration?.priceConfig && {
          ...configuration.priceConfig,
          price: configuration.priceConfig.price * (qty * product.stepper),
          comparativePrice: configuration.priceConfig?.comparativePrice && configuration.priceConfig.comparativePrice * qty,
        }}
        itemAvailabilityConfig={configuration?.itemAvailability}
        deliveryMethods={deliveryMethodsAllowed}
        hasMoreOptions={hasMoreOptions || !selectedWarehouse}
        onClickMoreOptions={onClickMoreOptions}
        disableAddToCart={selectedWarehouse ? (
          configuration.disableAddToCart || (
            !configuration.itemAvailability?.allowSaleWithoutInventory
            && (qty * product.stepper) > (configuration.itemAvailability?.currentQty || 0)
          )
        ) : loading}
        onAddToCart={!configuration.isManagedSale ? () => onAddToCart(qty * product.stepper) : undefined}
        onBuyNow={!configuration.isManagedSale ? () => onBuyNow(qty * product.stepper) : undefined}
        onContactAssociate={configuration.isManagedSale ? onContactAssociate : undefined}
        onIncreaseItemToAdd={onIncreaseItemToAddHandler}
        onDecreaseItemToAdd={onDecreaseItemToAddHandler}
        loading={loading}
        description={(
          <Description
            maskImage
            title={t('g.description')}
            heightLimit={200}
            decription={productDesc}
          />
        )}
      />
    )
  };

  const ProductRelatedSection = () => (
    <RelatedProducts 
      isLoading={loading}
      gridRules={{ gutter: 10, xs: 1, sm: 2, md: 3, lg: 1, xl: 2, xxl: 2 }}
      relatedProducts={relatedItems} 
    />
  );

  useEffect(() => {
    if (!product) return;

    getRelatedProducts(
      product.id, 
      selectedWarehouse ?? null,
      'product'
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, selectedWarehouse]);
  
  useEffect(() => {
    getProductConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouse]);

  useEffect(() => {
    onChangeSelection(product.id);
  }, []);
  
  useEffect(() => {
    // console.log({ productConfigDetail: configuration });

    if(configuration.visibility === EVisibility.Always) {
      return;
    }

    if(configuration.visibility === EVisibility.Hidden) {
      visibilityModal();
      return;
    }

    if(configuration.visibility === EVisibility.WithStock) {

      if (!configuration.itemAvailability) {
        // has no warehouse context; no itemAvailability, show product
        return;
      }
      
      if(!configuration.itemAvailability.allowSaleWithoutInventory
        && configuration.itemAvailability.withInventory === EInventoryStatus.WithoutInventory
      ) {
        visibilityModal();
      }
    }
  }, [configuration, visibilityModal]);

  return (
    <>
      {/* <Modal
        key='visibilityModalKey'
        title='Ups!'
        okText={t('g.back')}
        onOk={(closeModal: any) => {
          // console.log({ e });
          closeModal();
        }}
        maskStyle={{
          backdropFilter: 'blur(4px)',
        }}
        footer={[
          <Button
            key="link"
            href="/"
            type="primary"
            loading={loading}
            onClick={handleBack}
          >
            {t('g.back')}
          </Button>,
        ]}
        destroyOnClose
        cancelText={""}
        maskClosable={false}
        keyboard={false}
        open={visibilityModal.visible}
      >
        <Paragraph>{t('g.item_not_available')}</Paragraph>
      </Modal> */}
      {contextHolder}
      <Row gutter={[20, 20]}>
        {
          isScreenEqualsAndBelowMd ? (
            <Col flex="100%">
              <Title title={productName} subTitle={productSku} />
            </Col>
          ) : null
        }
        <Col style={{ width: !isScreenEqualsAndBelowMd ? "70%" : "100%" }}>
          {<ImagesPreview images={product.imgs} />}
        </Col>
        <Col flex={!isScreenEqualsAndBelowMd ? "30%" : "100%"}>
          {!isScreenEqualsAndBelowMd ? (
            <Card style={{ height: "100%" }}>
              <ProductPriceDetails />
            </Card>
          ) : (
            <ProductPriceDetails showTitle={false} />
          )}
        </Col>
        <Col flex={!isScreenEqualsAndBelowMd ? "70%" : "100%"}>
          <Row>
            {
              product.badges.length > 0 ? (
                <Col span={24}>
                  <BadgesList badges={product.badges} />
                </Col>
              ) : null
            }
            {
              product.attributeOptions.length > 0 ? (
                <>
                  <Divider />

                  <Col span={24}>
                    <ProductAttributeOptions attributeOptions={product.attributeOptions} />
                  </Col>
                </>
              ) : null
            }
            {
              attachments.length > 0 ? (
                <>
                  <Divider />
                  <Col span={24}>
                    <ItemAttachmentsSection
                      datasheets={datasheets}
                      guarantees={guarantees}
                      disclaimers={disclaimers}
                    />
                  </Col>
                </>
              ) : null
            }
            {isScreenEqualsAndBelowSm ? <Divider /> : null}
          </Row>
        </Col>
        {
          relatedItems.length > 0 &&
          (
            <Col flex={isScreenEqualsAndBelowMd ? '100%' : '30%'}>
              {
                isScreenEqualsAndAboveLg ? (
                  <Card style={{
                    maxHeight: '50rem',
                    overflowY: 'scroll',
                    padding: '8px',
                  }}>
                    <ProductRelatedSection />
                  </Card>
                ) : (
                  <ProductRelatedSection />
                )
              }
            </Col>
          )
        }
      </Row>
    </>
  );
};

export default ProductDetailsView;
