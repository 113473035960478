import Optional from '@/types/Optional';
import { Row, Typography, Col } from 'antd';
import { CSSProperties } from 'react';

const { Paragraph } = Typography;

interface IBannerProps {
  text: string;
  fontColor?: string;
  bgColor?: string;
  url?: Optional<string>;
  urlLabel?: Optional<string>;
}

const Banner = ({
  text,
  url,
  urlLabel,
  fontColor = '#FFFFFF',
  bgColor = '#292929',
}: IBannerProps) => {
  const rowWrapperStyle: CSSProperties = {
    lineHeight: 'normal',
    backgroundColor: bgColor,
  };  

  const containerStyle: CSSProperties = {
    padding: '8px 16px',
    lineHeight: 'normal',
    cursor: 'pointer',
  };
  const containerRowStyle: CSSProperties = {
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const mainTextStyle: CSSProperties = {
    color: fontColor,
    fontSize: 'medium',
    margin: '0px',
  };

  return (
    <Row justify='center' style={rowWrapperStyle}>
      <Col 
        span={24}
        style={containerStyle}
        onClick={
          url 
          ? () => window.open(url, '_blank') 
          : () => {}
        }
      >
        <Row justify="start" gutter={[16, 8]} style={containerRowStyle}>
          <Col span="auto">
            <Paragraph
              className="cursor-default"
              style={mainTextStyle} 
            >
              {text}  
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Banner;
