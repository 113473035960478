import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Modal, Row, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import MixSections from '@/components/Mix/MixSections';
import Description from '@/components/General/Description';
import IMix from '@/interfaces/IMix';
import IMixSection from '@/interfaces/IMixSection';
import IMixTopping from '@/interfaces/IMixTopping';
import IBaseProduct from '@/interfaces/IBaseProduct';
import SelectionMix from '@/interfaces/ISelectionMix';
import IMixBaseProduct from '@/interfaces/IMixBaseProduct';
import ISelectionSection from '@/interfaces/ISelectionSection';
import getTranlation from '@/helpers/getTranslation';
import useLocation from '@/hooks/useLocation';
import EPriceFormat from '@/enums/EPriceFormat';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import PriceDetails from '@/containers/views/ItemDetails/PriceDetails';
import useMixConfiguration from '@/containers/views/ItemDetails/useMixConfiguration';
import { isProductHaveShippingTypeInWarehouse } from '@/services/ShippingTypeInWarehouse';
import useBreakpoints from '@/hooks/useBreakpoint';
import BadgesList from '@/components/General/BadgesList';
import ImagesPreview from '@/components/ImagesPreview';
import Title from '@/components/General/Title/Title';
import RelatedProducts from '@/components/RelatedProducts';
import EVisibility from '@/enums/EVisibility';
import { useNavigate } from 'react-router-dom';
import useModal from '@/hooks/useModal';
import { isMixHaveDeliveryTypeInWarehouse } from '@/services/DeliveryPointService';

const { Paragraph } = Typography;

interface IMixDetailsView {
  mix: IMix;
  qty: number;
  hasMoreOptions?: boolean;
  onIncreaseItemToAdd: ( ) => void; 
  onDecreaseItemToAdd: () => void; 
  onClickMoreOptions?: () => void;
  onBuyNow?: (qtyToAdd?: number) => void;
  onAddToCart?: (qtyToAdd?: number) => void;
  onChangeSelection: React.Dispatch<React.SetStateAction<string | SelectionMix | undefined>>
}

export type SelectedToppingsType = ( 
  IMixTopping & {
    qtySelected?: number,
    sectionId: IMixSection['id'];
  }
);

const MixDetailsView = ({
  mix,
  qty,
  hasMoreOptions,
  onClickMoreOptions,
  onAddToCart = () => {},
  onBuyNow = () => {},
  onIncreaseItemToAdd,
  onDecreaseItemToAdd,
  onChangeSelection,
}: IMixDetailsView) => {
  const { 
    allowAddToCard,
    loading,
    price, 
    mixSection,
    selectedToppings,
    mixBaseProducts,
    mixRestrictions,
    mixHasInventory,
    getMixRestrictions,
    relatedItems,
    getRelatedProducts,
    onChangeToppings,
  } = useMixConfiguration({ mix });

  const { selectedWarehouse } = useLocation();
  const { t } = useTranslation('common');
  const { 
    isBreakpointEqualsAndBelow,
    isBreakpointEqualsAndAbove,
  } = useBreakpoints();

  const navigate = useNavigate();

  const mixWarnignModal = useModal();

  const [hasShippingType, setHasShippingType] = useState(false);
  const [hasDeliveryPoint, setHasDeliveryPoint] = useState(false);

  const isScreenEqualsAndBelowMd = isBreakpointEqualsAndBelow('md');
  const isScreenEqualsAndBelowSm = isBreakpointEqualsAndBelow('sm');
  const isScreenEqualsAndAboveLg = isBreakpointEqualsAndAbove('lg');
  const mixName = getTranlation(mix.name);
  const mixDesc = getTranlation(mix.desc);
  const mixSku = `SKU: ${mix.groupingCode}`;
  
  const deliveryMethodsAllowed: EDeliveryMethod[] = [];
  if (selectedWarehouse) {
    deliveryMethodsAllowed.push(EDeliveryMethod.Pickup)
  }
  if (hasShippingType) {
    deliveryMethodsAllowed.push(EDeliveryMethod.Shipping)
  }

  const selectedToppingsTypeToSelectionSection = (
    toppings: SelectedToppingsType[]
  ): ISelectionSection[] => {
    const selectionSections: ISelectionSection[] = [];

    toppings.forEach(topping => {
      const indexToAdd = selectionSections.findIndex(
        selectionSection => selectionSection.sectionId === topping.sectionId
      );

      if (indexToAdd < 0 && topping.qtySelected && topping?.qtySelected > 0) {
        selectionSections.push({
          products: [{
            productId: topping.product.id,
            qty: topping.qtySelected ?? 1
          }],
          sectionId: topping.sectionId,
        });
      }

      if (indexToAdd >= 0 && topping.qtySelected && topping?.qtySelected > 0) {
        selectionSections[indexToAdd].products.push({
          productId: topping.product.id,
          qty: topping.qtySelected ?? 1
        });
      }
    });

    return selectionSections;
  };

  const selectedBaseProducts = (baseProducts: IMixBaseProduct[]): IBaseProduct[] => {
    const newBaseProduct: IBaseProduct[] = baseProducts.map(baseProduct => {
      return {
        qty: baseProduct.qty,
        productId: baseProduct.product.id
      }
    });

    return newBaseProduct;
  };

  const MixPriceDetails = ({ showTitle = true }: { showTitle?: boolean }) => (
    <PriceDetails
      title={mixName}
      subtitle={mixSku}
      showTitle={showTitle}
      qtyInputConfig={{ 
        value: qty,
        disableIncrease: loading,
        disableDecrease: loading,
      }}
      priceConfig={{
        loading: loading,
        price: price * qty,
        priceFormat: EPriceFormat.Regular,
      }}
      deliveryMethods={deliveryMethodsAllowed}
      hasMoreOptions={hasMoreOptions || !selectedWarehouse}
      onClickMoreOptions={onClickMoreOptions}

      onAddToCart={() => onAddToCart(qty)}
      disableAddToCart={selectedWarehouse ? (!allowAddToCard || !mixHasInventory) : loading}
      onBuyNow={() => onBuyNow(qty)}

      onIncreaseItemToAdd={onIncreaseItemToAdd}
      onDecreaseItemToAdd={onDecreaseItemToAdd}
      
      loading={loading}

      description={(
        <Description
          maskImage
          title={t('g.description')}
          heightLimit={200}
          decription={mixDesc}
        />
      )}
    />
  );

  const ProductRelatedSection = () => (
    <RelatedProducts 
      isLoading={loading}
      gridRules={ isScreenEqualsAndAboveLg
        ? { gutter: 10, lg: 1, xl: 2, xxl: 2}
        : { gutter: 10, xs: 1, sm: 1, md: 1 }
      }
      relatedProducts={relatedItems} 
    />
  );

  useEffect(() => {
    if (!mix) {
      return;
    }

    getRelatedProducts(
      mix.id, 
      selectedWarehouse ?? null,
      'mix'
    );

    getMixRestrictions(mix, selectedWarehouse);
  }, [mix?.id, selectedWarehouse]);

  useEffect(() => {
    if (!selectedToppings || !mixBaseProducts) return;
    const selectionSections = selectedToppingsTypeToSelectionSection(selectedToppings);
    const selectionBaseProduct = selectedBaseProducts(mixBaseProducts);

    if ( selectedToppings ) {
      isProductHaveShippingTypeInWarehouse({
        productIds: [
          ...selectedToppings.map(top => top.product.id), 
          ...mixBaseProducts.map(bp => bp.product.id),
        ],
        warehouseId: selectedWarehouse,
      }).then(res => setHasShippingType(res));
    }

    if (selectedWarehouse) {
      isMixHaveDeliveryTypeInWarehouse({
        mixId: mix.id,
        warehouseId: selectedWarehouse,
      }).then(res => setHasDeliveryPoint(res));
    }

    onChangeSelection({
      baseProducts: selectionBaseProduct,
      sections: selectionSections
    });
  }, [selectedToppings, mixBaseProducts]);

  useEffect(() => {
    switch (mixRestrictions.visibility) {
      case EVisibility.Always:
      break;

      case EVisibility.Hidden:
        if(!mixWarnignModal.visible){
          mixWarnignModal.show();
        }
      break;

      case EVisibility.WithStock:
        if(!mixHasInventory && !mixWarnignModal.visible){
          mixWarnignModal.show();
        }
      break;
    }
  }, [mixRestrictions, mixHasInventory]);

  return (
    <Row gutter={[20, 20]}>
      <Modal 
        title="Ups!" 
        closeIcon={<></>}
        cancelButtonProps={{ hidden: true }}
        open={mixWarnignModal.visible} 
        okText={t('g.back')}
        onOk={() => navigate('/')} 
        maskStyle={{ backdropFilter: 'blur(4px)' }}
        maskClosable={false}
        keyboard={false}
      >
        <Paragraph>{t('g.item_not_available')}</Paragraph>
      </Modal>
      {
        isScreenEqualsAndBelowMd ? (
          <Col flex="100%">
            <Title title={mixName} />
          </Col>
        ): null
      }
      <Col style={{ width: !isScreenEqualsAndBelowMd ? "70%" : "100%" }}>
        <ImagesPreview images={mix.imgs} />
      </Col>
      <Col flex={!isScreenEqualsAndBelowMd ? "30%" : "100%"}>
        {!isScreenEqualsAndBelowMd ? (
          <Card style={{ height: "100%" }}>
            <MixPriceDetails/>
          </Card>
        ): (
          <MixPriceDetails showTitle={false}/>
        )}
      </Col>
      <Col flex={!isScreenEqualsAndBelowMd ? "70%" : "100%"}>
        <Row>
          {
            mix.badges.length > 0 ? (
              <Col span={24}>
                <BadgesList badges={mix.badges}/>
              </Col>
            ) : null
          }
          {
            mixBaseProducts.length !== 0 ? (
              <>
                <Divider />
                <Col span={24}>
                  <Typography.Title level={5}>
                    {t('g.base_products')}:
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  {
                    mixBaseProducts.map(({ qty, product})=> (
                      <Tag key={`baseProduct-${product.id}`}>
                        {`[${qty} ${product.unit.code}] ${getTranlation(product.name)}`}
                      </Tag>
                    ))
                  }
                </Col>
              </>
            ) : null
          }
          { 
            mixSection.length > 0 ? (
              <Col span={24}>
                <Divider />
                <MixSections 
                  loading={loading}
                  sections={mixSection}
                  selectedOptions={selectedToppings}
                  onChange={onChangeToppings}
                  />
              </Col>
            ) : null
          }
          { isScreenEqualsAndBelowSm ? <Divider /> : null }
        </Row>
      </Col>
      {
        relatedItems.length > 0 && 
        (
          <Col flex={isScreenEqualsAndBelowMd ? '100%': '30%'}>
            {
              isScreenEqualsAndAboveLg ? (
                <Card style={{ 
                  maxHeight: '50rem',
                  overflowY: 'scroll', 
                  padding: '8px',
                }}>
                  <ProductRelatedSection />
                </Card>
              ): (
                <ProductRelatedSection />
              )
            }
          </Col>
        )
      }
    </Row>
  );
};

export default MixDetailsView;
