import React from 'react';
import { 
  Row, 
  Space,
  Button,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';

import DeliveryMethod from '@/components/DeliveryMethod';
import Price, { IPrice } from '@/components/General/Price';
import QtyInput, { IQtyInput } from '@/components/General/QtyInput';
import ItemAvailability, { IItemAvailability } from '@/components/Item/ItemAvailability';

import EDeliveryMethod from '@/enums/EDeliveryMethod';
import Title from '@/components/General/Title/Title';

interface IPriceDetails {
  title?: string;
  subtitle?: string;
  showTitle?: boolean;
  qtyInputConfig?: IQtyInput;
  min?: number;
  priceConfig?: IPrice;
  itemAvailabilityConfig?: IItemAvailability;
  deliveryMethods?: EDeliveryMethod[];
  
  loading?: boolean;
  border?: boolean;
  
  description?: React.ReactNode;

  disableAddToCart?: boolean;
  disableContactAssociate?: boolean;
  hasMoreOptions?: boolean;
  onClickMoreOptions?: () => void;

  onBuyNow?: () => void;
  onAddToCart?: () => void;
  onIncreaseItemToAdd?: () => void; 
  onDecreaseItemToAdd?: () => void; 
  onContactAssociate?: () => void;
}

const PriceDetails = ({
  title,
  subtitle,
  showTitle = true,
  qtyInputConfig,
  priceConfig,
  itemAvailabilityConfig,
  deliveryMethods = [],

  loading = false,
  border = false,
  disableAddToCart = false,
  disableContactAssociate = false,
  hasMoreOptions = false,
  description,
  onClickMoreOptions,

  onBuyNow,
  onAddToCart,
  onContactAssociate,
  onIncreaseItemToAdd = () => {}, 
  onDecreaseItemToAdd = () => {},
}: IPriceDetails) => {
  const { t } = useTranslation('common');

  const spaceStyle = {
    width: '100%', 
  }
  const borderStyle = {
    border: '1px solid #e6e6e6', 
    padding: '16px', 
    borderRadius: '4px',
  }
  const priceStyle = {
    fontSize: '1.75rem', 
  }

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" size="middle" style={{ ...spaceStyle, ...(border && borderStyle)}}>
          {showTitle ? <Title title={title} subTitle={subtitle}/> : null}
          { priceConfig && <Price {...priceConfig} stylePrice={priceStyle} loading={priceConfig.loading || loading} />}
          { 
            deliveryMethods && 
            <Row gutter={8}>
              {
                deliveryMethods.map((dm) => (
                  <Col key={dm}>
                    <DeliveryMethod deliveryMethod={dm} hideText />
                  </Col>
                )) 
              }
            </Row>
          }
          { (hasMoreOptions) 
            && onClickMoreOptions 
            && (
              <Button style={{ padding: '0px' }} type="link" onClick={onClickMoreOptions}>{t('g.see_more_stores')}</Button>
          )}
          { itemAvailabilityConfig && <ItemAvailability {...itemAvailabilityConfig} />}
          { qtyInputConfig && (
            <QtyInput
              {...qtyInputConfig} 
              onIncrease={onIncreaseItemToAdd} 
              onDecrease={onDecreaseItemToAdd}
            />
          )}
          { onBuyNow && <Button type="primary" block onClick={onBuyNow} disabled={disableAddToCart}>{t('g.buy_now')}</Button> }
          { onAddToCart && <Button type="default" block onClick={onAddToCart} disabled={disableAddToCart}>{t('g.add_to_cart')}</Button> }
          { onContactAssociate && <Button type="primary" block onClick={onContactAssociate} disabled={disableContactAssociate}>{t('g.contact_associate')}</Button> }
          { description }
        </Space>
      </Col>
    </Row>
  );
};

export default PriceDetails;
