import React from 'react';
import { Typography, Space, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from '@/hooks/useAuth';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

const { Link, Text } = Typography;

interface ISiderFooter {
  onCloseDrawer: () => void
}


const SiderFooter = ({
  onCloseDrawer
}: ISiderFooter) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { authenticated, logout } = useAuth();
  const isAuth = authenticated;

  const onLogin = () => {
    navigate('/login');
  };
  const onLogout = async () => {
    await logout();
    onCloseDrawer();
    navigate('/');
  };
  
  const CURRENT_VERSION = process.env.REACT_APP_VERSION;
  const BILLING_URL = '/billing';
  const FAQS_URL = process.env.REACT_APP_FAQS_URL;
  const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;
  const CONTACT_PHONE = process.env.REACT_APP_CONTACT_PHONE;
  const CONTACT_ADDRESS = process.env.REACT_APP_CONTACT_ADDRESS;
  const CONTACT_MAIL = process.env.REACT_APP_CONTACT_MAIL;
  const TERMS_AND_CONDITIONS_URL = process.env.REACT_APP_TERMS_AND_CONDITIONS_URL;
  const REACT_APP_PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
  const SIDEBAR_EXTRA_TEXT = process.env.REACT_APP_SIDEBAR_EXTRA_TEXT;
  const SIDEBAR_EXTRA_URL = process.env.REACT_APP_SIDEBAR_EXTRA_URL;

  const contactStyle = {
    fontSize: '10px',
  }

  return (
    <div>
      {/* <Typography.Paragraph>
        Español
      </Typography.Paragraph> */}
      <Space direction="vertical" size="middle">
        { isAuth 
          ? (<Button 
              type="default" 
              icon={<LogoutOutlined />}
              onClick={onLogout}
            >
              {t('g.log_out')}
            </Button>) 
          : (<Button 
              type="primary"
              className="bg-primary"
              icon={<LoginOutlined />}
              onClick={onLogin}
            >
              {t('g.log_in')}
            </Button>) 
        }
        {SIDEBAR_EXTRA_TEXT && SIDEBAR_EXTRA_URL ? (
          <Link href={SIDEBAR_EXTRA_URL} target="_blank" type="secondary">
            {SIDEBAR_EXTRA_TEXT}
          </Link>
        ) : null}
        <Link href={BILLING_URL} target="_blank" type="secondary">
          {t('g.request_bill')}
        </Link>
        {
          FAQS_URL && 
          <Link href={FAQS_URL} target="_blank" type="secondary">
            {t('g.faqs')}
          </Link>
        }
        <Space direction="vertical" size={[0,4]}>
          {
            CONTACT_URL &&
            <Text type="secondary">
              {t('g.contact')}
            </Text>
          }
          {
            CONTACT_MAIL &&
            <Link style={contactStyle} href={`mailto:${CONTACT_MAIL}`} target="_blank" type="secondary">{CONTACT_MAIL}</Link>
          }
          {
            CONTACT_PHONE &&
            <Link style={contactStyle} href={`tel:${CONTACT_PHONE}`} target="_blank" type="secondary">{CONTACT_PHONE}</Link>
          }
          {
            CONTACT_ADDRESS &&
            <Text style={contactStyle} type="secondary">{CONTACT_ADDRESS}</Text>
          }
        </Space>
        {
          TERMS_AND_CONDITIONS_URL &&
          <Link href={TERMS_AND_CONDITIONS_URL} target="_blank" type="secondary">
            {t('g.terms_and_cond')}
          </Link>
        }
        {
          REACT_APP_PRIVACY_POLICY_URL &&
          <Link href={REACT_APP_PRIVACY_POLICY_URL} target="_blank" type="secondary">
            {t('g.privacy_policy')}
          </Link>
        }
        <Typography.Paragraph type="secondary">
          v{CURRENT_VERSION}
        </Typography.Paragraph>
      </Space>
    </div>
  );
};

export default SiderFooter;
